import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { useLobbyGameApi } from '~/api/lobby-game'
import { EMPTY_CONTENT } from '~/constants/lobby'
import { useGameStore } from '~/store/game'
import { useLoading } from '~/composables/useLoading'
import { RESPONSE_STATUS } from '~/config/constant'
import { IItemCongGame, IProvider, IFiterGameCasinoItem } from '~/types/lobby.type'

export const useCasino = () => {
  const { $device, $pinia, $axios } = useNuxtApp()
  const store = useGameStore($pinia)
  const { LOBBY_CATEGORY, LIST_LOBBY_CASINO } = useLobbyGameApi()
  const route = useRoute()
  const { loading, load, isLoading } = useLoading()
  const { listFilterGameCasino } = storeToRefs(store)

  const allObject = {
    code: 'all_provider',
    name: 'Tất cả',
    url: '',
    image_path: ''
  }

  const limit = ref<number>(30)
  const page = ref<number>(1)
  const totalItem = ref<number>(0)
  const listCasino = ref<IItemCongGame[]>([])
  const listProvider = ref<IProvider[]>([])
  const placeholderCount = ref<number>(0)
  const totalItemPerPage = computed(() => listCasino?.value?.length || 0)
  const typeQuery = computed(() => route.params?.type as string)
  const partnerQuery = computed(() => route.query?.partner as string)

  const currentCategory = ref<IFiterGameCasinoItem | null>(null)
  const currentProvider = ref<IProvider | null>(allObject)

  const isLoadMore = computed(() => {
    return totalItemPerPage.value < totalItem.value
  })

  const initLoadData = async () => {
    page.value = 1
    if (listFilterGameCasino.value.length) {
      setFilteredProviderList(listFilterGameCasino.value)
      currentCategory.value = listFilterGameCasino.value.find((item) => item.alias === typeQuery.value) || null
      currentProvider.value = listProvider.value.find((item) => item.code === partnerQuery.value) || allObject
    }
    await Promise.allSettled([fetchListCasino()])
  }

  const handleChangeProvider = (item: IProvider) => {
    const _query = { ...route.query }
    if (item?.code) {
      navigateTo({
        query: { ..._query, partner: item?.code }
      })
    }
  }

  const setFilteredProviderList = (data: IFiterGameCasinoItem[]) => {
    const type = (route?.params?.type as string) || 'all'
    const filteredItem = data.find((item) => item?.alias === type)
    listProvider.value = filteredItem?.providers || []
    const isAllExists = listProvider.value.some((item) => item.code === 'all_provider')
    if (!isAllExists) {
      listProvider.value.unshift(allObject)
    }
  }

  const fetchListCasino = async ($state?: any) => {
    loading()
    const gameType = route.params.type
    const partnerProvider = partnerQuery.value
    const queryUrl = queryString.stringify({
      path_alias: gameType,
      provider_code: partnerProvider && partnerProvider !== 'all_provider' ? partnerProvider : '',
      limit: limit.value,
      page: page.value
    })

    try {
      const { data: response } = await $axios.get(`${LIST_LOBBY_CASINO}?${queryUrl}`)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        if (page.value > 1) {
          listCasino.value = listCasino.value.concat(response.data?.items)
        } else {
          listCasino.value = response.data?.items
        }
        totalItem.value = response.data?.total

        if (response.data.items?.length < limit.value && $state) {
          $state?.complete()
        } else if ($state) {
          $state?.loaded()
        }
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    } finally {
      load()
    }
  }

  const changePagination = ($state: any) => {
    page.value += 1
    fetchListCasino($state)
  }

  const calculatePlaceholder = () => {
    const totalItems = listCasino.value?.length || 0
    let itemsPerRow
    if ($device.isDesktop) {
      itemsPerRow = 6
    } else {
      itemsPerRow = 2
    }

    const remainder = totalItems % itemsPerRow
    placeholderCount.value = remainder === 0 ? 0 : itemsPerRow - remainder
  }

  watch(listCasino, () => {
    if (listCasino.value && listCasino.value.length > 0) {
      calculatePlaceholder()
    }
  })

  const fetchListFilterCasino = async () => {
    loading()
    try {
      const { data: response } = await $axios.get(`${LOBBY_CATEGORY}?alias=livecasino`)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        const listFilter = response?.data || []
        store.setListFilterGameCasino(listFilter)
        setFilteredProviderList(listFilter)
      }
    } catch (error: any) {
      console.log('error', error?.response?.data?.message)
    }
    load()
  }

  const fetchLiveCasino = (queryUrl: string) => {
    return $axios.get(`${LIST_LOBBY_CASINO}?${queryUrl}`)
  }

  return {
    fetchListCasino,
    handleChangeProvider,
    initLoadData,
    changePagination,
    listProvider,
    listCasino,
    isLoading,
    totalItem,
    totalItemPerPage,
    EMPTY_CONTENT,
    calculatePlaceholder,
    placeholderCount,
    isLoadMore,
    typeQuery,
    partnerQuery,
    fetchListFilterCasino,
    page,
    fetchLiveCasino,
    currentCategory,
    currentProvider
  }
}
